/*
 * @Author       : suzhen
 * @Email        : suzhen@dianzhong.com
 * @Date         : 2022-09-20 10:28:03
 * @LastEditors  : your name
 * @LastEditTime : 2022-11-21 17:29:41
 * @Description  : 用户权限验证
 */
import router from './router/index.js'
import store from './store/index'
import { h5Log } from '@/utils'
import { getUserId, setStorage, getStorage } from '@/utils/cookie.js'
const whiteList = ['/login', '/pay', '/paycode'] // 白名单,不需要登录的路由
const hym = location.host.split('.')[0]

router.beforeEach((to, from, next) => {
	// 尝试获取cookie中的userId
	if (getUserId()) {
		// 有token缓存 用户已刷新
		if(to.path == '/login') {
			// 去往广场页
			router.replace({path: '/'})
		} else {
			next();
		}
	} else {
		// 如果前往的路径是白名单内的,就可以直接前往
		let backURL = window.sessionStorage.getItem('backURL')
		let reg = /^http(s)?:\/\/(.*?)\//
		if (whiteList.indexOf(to.path) !== -1) {
			// next()
			if(hym.startsWith('wx') || process.env.NODE_ENV != 'production') { // 业务域名
				next()
			} else { // 支付域名
				if(to.path != '/login') next()
				else {
					// 去往业务域名下的登录
					if(backURL){
						let host = reg.exec(backURL)[0]
						location.href = host+'login' || 'http://wxd0c0a9cc2eca0d26.videoh5.cps104.dzods.cn/login'
					}
				}
			}
			
		} else {
			if(hym.startsWith('wx') || process.env.NODE_ENV != 'production') { // 业务域名
				// 需要登录时=》记录当前的推广地址
				let perQuery = getStorage('readQuery', true) || {}
				setStorage('readPath', to.href)
				setStorage('readQuery', { ...perQuery, ...to.query})
				// 如果路径不是白名单内的,而且又没有登录,就转到登录页
				next('/login')
				h5Log('onLaunch', 'enter', 'event', {path: to.href, query: { ...perQuery, ...to.query}})
			} else {
				// 跳往业务域名下的指定路由
				if(backURL){
					let host = reg.exec(backURL)[0]
					location.href = host+'login' || 'http://wxd0c0a9cc2eca0d26.videoh5.cps104.dzods.cn/'
				}
			}
		}
	}
})


router.afterEach((to) => {
	if(getUserId()) {
		if(!to.name) { // 保底，当无路由组件时显示首页
			let name = store.state.user.defaultRoute
			router.replace({ name })
		}
		document.querySelector('title').innerText = to.meta.title || '短剧'

	}
})

