/*
 * @Author       : suzhen
 * @Email        : suzhen@dianzhong.com
 * @Date         : 2022-09-19 11:42:43
 * @LastEditors  : your name
 * @LastEditTime : 2022-12-26 14:11:10
 * @Description  : cookie验证
 */
import Cookies from 'js-cookie'
const UserIdKey = 'cps-wxvideo-id'
const nolist = ['/login', '/pay', '/paycode']
const roothost = location.host.split('.')[0]
import { h5Log, getQueryString } from '@/utils'

const expires = 365

// function getDomain(){
//   let path = location.host.split('.')
//   if(isNaN(path[0] - 0)){ // 测试预发线上
//     path =  '.' + path.slice(1).join('.')
//   } else { // 本地环境
//     path = null
//   }
//   return path
// }

// uerId
export function getUserId() {
  if(Cookies.get(UserIdKey) && getStorage('hoid')){
    return Cookies.get(UserIdKey)
  }else if(Cookies.get(UserIdKey) && !getStorage('hoid')){
    removeUserId()
    let pageQuery = getQueryString(location.search) || {}
    // window.location.replace(location.origin)
    h5Log('noOpenid','err','event',{now:pageQuery})
    return '';
  }else {
    if(nolist.indexOf(location.pathname) == -1 && roothost.startsWith('wx') > -1 ) { // 业务域名下非正常丢失缓存
      setStorage('readPath', window.location.pathname + window.location.search)
      // 此处禁止加打点，容易造成并发循环登录，打点中有去获取userid
      console.log('需要重新去登录')
      location.href = location.origin + '/login'
    }
    return '';
  }
}

export function setUserId(id) {
  return Cookies.set(UserIdKey, id, {expires})
}

export function removeUserId() {
  return Cookies.remove(UserIdKey)
}

/**
 * @description: 本地存储
 * @param { String } key 名称
 * @param { any } value  value
 * @return {*}
 */
export function setStorage(key, value) {
  if(!value) return false
  if(typeof value == 'string' || typeof value == 'number') {
    localStorage.setItem(key, value)
  } else {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(error)
    }
  }
}

/**
 * @description: 读取本地存储
 * @param { String } key 名称
 * @param { Boolean } isObject 值是否是对象
 * @return {*} 有返回值
 */
export function getStorage(key,isObject) {
  if(!key) return false
  try {
    let data = localStorage.getItem(key) || ''
    if(isObject) {
      if(data) data = JSON.parse(data)
      else data = {}
    }
    return data
  } catch (error) {
    console.log(error)
  }
}

export function removeStorage(key) {
  localStorage.removeItem(key)
}

// 绘画存储
export function setSessionStorage(key, value) {
  if(!value) return false
  if(typeof value == 'string' || typeof value == 'number') {
    sessionStorage.setItem(key, value)
  } else {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(error)
    }
  }
}

export function getSessionStorage(key,isObject) {
  if(!key) return false
  try {
    let data = sessionStorage.getItem(key)
    if(isObject) {
      data = JSON.parse(data)
    }
    return data
  } catch (error) {
    console.log(error)
  }
}

export function removeAllStorage() {
  try {
    localStorage.clear();
    Cookies.remove(UserIdKey)
  } catch (error) {
    console.log(error)
  }
}

export function removeSessionStorage(key) {
  sessionStorage.removeItem(key)
}

