/*
 * @Author       : suzhen
 * @Email        : suzhen@dianzhong.com
 * @Date         : 2022-09-19 11:42:43
 * @LastEditors  : your name
 * @LastEditTime : 2022-11-21 17:29:18
 * @Description  : 文件简介
 */
import { createRouter, createWebHistory } from 'vue-router'

// 活动路由 custom
const routes = [
  {
    path: '/smalltheater',
    name: 'smallTheater',
    component: () => import('../views/smallTheater/index.vue'),
    meta: {
      keepAlive: true,
      title: '广场'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      keepAlive: false,
      title: '登录'
    }
  },
  {
    path: '/paycode',
    name: 'paycode',
    component: () => import('../views/login/paycode.vue'),
    meta: {
      keepAlive: false,
      title: '登录'
    }
  },
  {
    path: '/videocode',
    name: 'videocode',
    component: () => import('../views/login/videocode.vue'),
    meta: {
      keepAlive: false,
      title: '登录'
    }
  },
  {
    path: '/myinfo',
    name: 'myinfo',
    component: () => import('../views/myInfo/myInfo.vue'),
    meta: {
      keepAlive: false,
      title: '我的'
    }
  },
  {
    path: '/mylike',
    name: 'myLike',
    component: () => import('../views/myInfo/myLike/myLike.vue'),
    meta: {
      keepAlive: false,
      title: '喜欢'
    }
  },
  {
    path: '/myorder',
    name: 'myOrder',
    component: () => import('../views/myOrder/myOrder.vue'),
    meta: {
      keepAlive: false,
      title: '订单'
    }
  },
  {
    path: '/contactweb',
    name: 'contactweb',
    component: () => import('../views/contactWeb/index.vue'),
    meta: {
      keepAlive: false,
      title: '联系客服'
    }
  },
  {
    path: '/profit',
    name: 'profit',
    component: () => import('../views/profit/profit.vue'),
    meta: {
      keepAlive: false,
      title: '福利中心'
    }
  },
  {
    path: '/bingewatching',
    name: 'bingewatching',
    component: () => import('../views/bingeWatching/index.vue'),
    meta: {
      keepAlive: false,
      title: '追剧'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge/index.vue'),
    meta: {
      keepAlive: false,
      title: '支付'
    }
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('../views/ranking/index.vue'),
    meta: {
      keepAlive: true,
      title: '排行榜'
    }
  },
  {
    path: '/viprecharge',
    name: 'vipRecharge',
    component: () => import('../views/vipRecharge/index.vue'),
    meta: {
      keepAlive: false,
      title: 'vip支付'
    }
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import('../views/custom/index.vue'),
    meta: {
      keepAlive: false,
      title: '活动'
    }
  },
  {
    path: '',
    redirect: '/smalltheater',
  },
  {
    path: '/',
    redirect: '/smalltheater',
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/errPage/404.vue'),
    meta: {
      keepAlive: false,
      title: '404'
    }
  },
  {
    path: '/videoinfosub',
    name: 'myVideo',
    component: () => import('../views/videoInfoSub/index.vue'),
    meta: {
      keepAlive: false,
      title: ''
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay/index.vue'),
    meta: {
      keepAlive: false,
      title: ''
    }
  },
  {
    path: '/searchpage',
    name: 'searchPage',
    component: () => import('../views/searchPage/index.vue'),
    meta: {
      keepAlive: false,
      title: ''
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
