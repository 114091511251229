import router from './router/index.js';
import { h5Log, getQueryString, getAppId } from '@/utils';
import { getUserId, setStorage, getStorage, setSessionStorage } from '@/utils/cookie.js';
import { onMounted } from '@vue/runtime-core';
import request from '@/plugins';
import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
import { ref } from 'vue';
export default {
  setup() {
    onMounted(() => {
      // 有用户id初次渲染完时保存页面参数
      if (getUserId()) {
        let perQuery = getStorage('readQuery', true) || {};
        let pageQuery = getQueryString(location.search) || {};
        if (pageQuery.code) delete pageQuery.code; // setStorage('readQuery', { ...perQuery, ...pageQuery })

        if (pageQuery && !!pageQuery.autoSign) {
          // 自动签到弹窗
          setSessionStorage('autoSign', pageQuery.autoSign);
        }

        h5Log('App', 'enter', 'page_view', {
          now: pageQuery,
          query: { ...perQuery,
            ...pageQuery
          }
        }); // if(Object.values(pageQuery).length > 0) {

        request.post('5300', {
          code: 'code',
          ...pageQuery
        }).then(res => {
          if (res.data && res.data.userId) {
            setStorage('readQuery', { ...perQuery,
              ...pageQuery,
              channelId: res.data.channelId
            });
            h5Log('updateUser', 'success', 'event', { ...res.data
            }); // 【兼容统一支付获取code】 《《《《

            let payappid = res.data.appId || '';

            if (!pageQuery.openId && payappid) {
              // 支付域名code之后重定向回来携带openid 既不走以下逻辑
              if (payappid && getStorage('payAppId') != payappid && res.data.channelPayType === 0) {
                // 先检查本地缓存是否存在支付域名下的appid与现指定的是否一致
                setStorage('readPath', window.location.pathname + window.location.search); // 非统一支付下清楚统一支付的信息

                setStorage('poid', '');
                setStorage('payAppId', '');
                let redirect_uri = encodeURIComponent(res.data.payHost + '/paycode?workUrl=' + origin + '/videocode&appId=' + res.data.appId + '&wxid=' + getAppId());
                let payhref = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
                console.log('app授权支付', payhref);
                window.location.href = payhref;
              } else if (res.data.channelPayType == 1) {
                // 非统一支付下清楚统一支付的信息
                setStorage('poid', '');
                setStorage('payAppId', '');
              }
            }

            if (pageQuery.openId == '12345' && res.data.channelPayType === 0) {
              Toast('支付授权失败');
              console.log('pageQuery页面参数', pageQuery);
            } // 【兼容统一支付获取code】 》》》》

          }
        }, err => {
          h5Log('updateUser', 'err', 'event', {
            err: err
          });
          console.log('err', err);
        }); // }
      }

      try {
        if (wx.config) {
          wx.config({
            debug: false,
            appId: "111",
            timestamp: "111",
            nonceStr: "111",
            signature: "111",
            jsApiList: []
          });
          wx.ready(function () {
            wx.getNetworkType({
              success: function (res) {
                var networkType = res.networkType; // 返回网络类型2g，3g，4g，wifi

                setSessionStorage('network', networkType);
              }
            });
          });
        }
      } catch (e) {
        console.log(e);
      }
    });
    const active = ref(0);
    const isShowTab = ref(true);
    const icon = {
      active1: require('@/assets/tabicon/tab_select_icon1.png'),
      inactive1: require('@/assets/tabicon/tab_icon1.png'),
      active2: require('@/assets/tabicon/tab_select_icon2.png'),
      inactive2: require('@/assets/tabicon/tab_icon2.png'),
      active3: require('@/assets/tabicon/tab_select_icon3.png'),
      inactive3: require('@/assets/tabicon/tab_icon3.png')
    }; // 监听当前页面是否是tab页面

    router.afterEach(to => {
      let tabList = ['/bingewatching', '/smalltheater', '/myinfo'];

      if (tabList.indexOf(to.path) > -1) {
        isShowTab.value = true;
      } else {
        isShowTab.value = false;
      }
    }); // 监听tab切换

    const onChange = function (index) {
      pageLog('tab_click', {
        index
      });
    };

    const pageLog = function (type, obj) {
      let key1 = 'app';
      let key2 = '';
      let action = '';
      let common = {};
      let params = Object.assign(common, obj || {});

      switch (type) {
        case 'tab_click':
          key2 = 'tab';
          action = 'click';
          break;
      }

      h5Log(key1, key2, action, params);
    };

    return {
      icon,
      active,
      isShowTab,
      onChange
    };
  }

};