/*
 * @Author       : suzhen
 * @Email        : suzhen@dianzhong.com
 * @Date         : 2022-07-23 16:36:23
 * @LastEditors  : your name
 * @LastEditTime : 2022-12-26 14:44:26
 * @Description  : 启动文件
 */
import { createApp } from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import axiosApi from './plugins/index'
import { setStorage, getStorage } from '@/utils/cookie.js'
import { randomStr, h5Log } from '@/utils'
import 'lib-flexible/flexible.js'

if(!getStorage('utdid')) {
    setStorage('utdid', randomStr())
}
setStorage('sessionid', randomStr(20))

import './permission.js'

// 预发布线上需要注释的代码
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

import { Button, Swipe, SwipeItem, List, Tabbar, TabbarItem, NavBar, Slider, Popup, Lazyload, Loading } from 'vant'
import { Image as VanImage } from 'vant';

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css' //样式文件
// import VuevideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'

import 'vant/es/toast/style'

const app = createApp(App)
app.config.errorHandler = function (err, vm, info) {
    if(!vm) return false
    let filename = vm.$options.__file
    if(!filename){
        if(vm.$parent.$options.__file) filename='parent --- ' + vm.$parent.$options.__file
        else filename='parent --- ' + vm.$parent.$options.name
    }
    let err_obj = {
        message: err.message,
        errname: err.name,
        filename: filename || '未抓取',
        href: location.href,
        stack:  err.stack,
        mark: info + '---' + err.stack.split('\n')[1].split('(')[0].trim(),
    }
    console.log('错误触发上报机制', err)
    h5Log('page', 'err', 'event', err_obj)
}

app.config.globalProperties.$axiosApi = axiosApi
// app.use(vConsole)
app.use(Button).use(Swipe).use(SwipeItem).use(List).use(Tabbar).use(TabbarItem).use(NavBar).use(Slider).use(Popup).use(VanImage).use(Lazyload).use(Loading)
.use(store).use(router).mount('#app')
